<template>
   <v-card class="mx-auto">
    <v-card-title class="font-weight-bold">
        All Donations
    </v-card-title>
    <v-divider />
    <div class="skeleton-list-item w-full" v-if="hourlyReportLoading">
        <v-skeleton-loader  :loading="true" type="image"></v-skeleton-loader>
    </div>
    <v-simple-table class="pa-4 mt-2" v-if="!hourlyReportLoading">
      <template v-slot:default>
        <tbody class="simple-table-borders">
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-right border-left">Once</td>
                <td class="text-right border-right font-weight-bold">No. of donations added to the tally</td>
                <td class="px-15 text-center border-right">
                    {{AllDonationSummary && AllDonationSummary.once_count ? AllDonationSummary.once_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations added to the tally</td>
                <td class="px-15 text-center border-right">
                    {{AllDonationSummary && AllDonationSummary.once_amount ? AllDonationSummary.once_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-top border-right border-left">Recurring</td>
                <td class="text-right border-right border-top border-top font-weight-bold">No. of donations added to the tally</td>
                <td class="px-15 text-center border-right border-top">
                    {{AllDonationSummary && AllDonationSummary.rec_count ? AllDonationSummary.rec_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations received</td>
                <td class="px-15 text-center border-right">
                    {{AllDonationSummary && AllDonationSummary.rec_amount ? AllDonationSummary.rec_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of new Donors</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.new_donors ? AllDonationSummary.new_donors : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentive Donations</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.incentive_donation ? AllDonationSummary.incentive_donation : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentives</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.incentive_count ? AllDonationSummary.incentive_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No. of donations added the inspiration fund</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.inspiration_count ? AllDonationSummary.inspiration_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ value of donations added to the inspiration fund</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.inspiration_amount ? AllDonationSummary.inspiration_amount : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">Total no. of donations</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.donation_count ? AllDonationSummary.donation_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ Total value of donations</td>
                <td class="px-15 text-center border-right border-none">
                    {{AllDonationSummary && AllDonationSummary.donation_amount ? AllDonationSummary.donation_amount : 0}}
                </td>
            </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['hourlyReportLoading', 'AllDonationSummary'])
  }
}
</script>
